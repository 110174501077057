<template>
  <div>
    <vs-table :data="lunchOptionsData" @selected="showCustomerDetails"
              :pagination="lunchOptionsData.length > maxItems ? true : false" :max-items="maxItems" search>
      <template slot="header">

        <div class="d-inline-block float-left">
          <h2 v-if="filter === 'active'">Aktivne možnosti donacij za enoto</h2>
          <h2 v-if="filter === 'inactive'">Neaktivne možnosti donacij za enoto</h2>
        </div>
        <vs-chip class="mt-1 ml-3">{{ lunchOptionsData.length }}</vs-chip>

      </template>
      <template slot="thead">
        <vs-th>
          Logo
        </vs-th>
        <vs-th>
          Enota
        </vs-th>
        <vs-th>
          Podjetje
        </vs-th>
        <vs-th>
          Tip
        </vs-th>
        <vs-th sort-key="donor_type_id">
          Dnevi
        </vs-th>
        <vs-th>
          Akcije
        </vs-th>

      </template>

      <template slot-scope="{data}">
        <vs-tr :key="index" v-for="(tr, index) in data" :data="tr">
          <vs-td :data="data[index].donor">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <div class="avatarWithStatus">
                <vs-avatar class="companyLogo" v-if="data[index].donor.donorLogo" size="60px"
                           :src="$globalFunctions.getImage(data[index].donor.donorLogo)"/>
                <vs-avatar v-if="!data[index].donor.donorLogo" size="60px"
                           :text="data[index].donor.companyDisplayName"/>

                <vs-avatar v-if="data[index].active === 1" size="small" class="status" color="success"
                           icon="check_circle"/>
                <vs-avatar v-if="data[index].active === 0" size="small" class="status" color="danger"
                           icon="radio_button_unchecked"/>
              </div>
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].unit">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ data[index].unit.name }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].donor">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ data[index].donor.companyDisplayName }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].type">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ data[index].type.name }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].cron">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ getDayNames(data[index].cron) }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].id">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

              <vs-button type="filled" color="primary" icon="edit" size="small"
                         class="vs-lg-12 mt-2" @click.stop="editLunchOption(data[index])">
                Uredi
              </vs-button>
              <vs-button type="filled" color="danger" icon="edit" size="small"
                         class="vs-lg-12 mt-2 ml-2"
                         @click.stop="beforeDeleteLunchOption(data[index])">Izbiriši
              </vs-button>


            </vs-col>
          </vs-td>

        </vs-tr>
      </template>


    </vs-table>
  </div>
</template>

<script>
import Vue from 'vue';


export default {
  name: 'LunchesTable',
  props: {
    lunchOptionsData: Array,
    filter: String
  },
  components: {},

  data() {
    return {
      maxItems: 500
    }
  },

  computed: {},

  mounted() {
    const _this = this;

  },

  destroyed() {
    console.log("destroyed");
    const _this = this;

  },

  beforeDestroy() {
    console.log("beforeDestroy");
    const _this = this;


  },

  methods: {

    getDayNames(cronString) {
      let cronArr = cronString.replace(/\*/g, '').replace(/\ /g, '').split(',').sort();

      let daysArray = ['nedelja', 'ponedeljek', 'torek', 'sreda', 'četrtek', 'petek', 'sobota'];
      let days = [];
      _.each(cronArr, function (day) {
        days.push(daysArray[day]);
      });

      return days.map((item) => {
        return (item)
      }).join(", ")
    },

    showCustomerDetails(tr) {
      const _this = this;

      _this.$emit('showCompaniesDetails', tr);
    },

    stopTheEvent: (event) => {
      event.stopPropagation();
      event.preventDefault();
    },

    editLunchOption(lunchOption) {
      const _this = this;
      console.log("lunchOption", lunchOption.id);

      _this.$router.push({name: 'Kosilo', params: {lunchOptionId: lunchOption.id}});
    },

    beforeDeleteLunchOption(lunchOption) {
      const _this = this;
      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati možnost kosila pri ' + lunchOption.donor.companyDisplayName + ' ?',
        message: 'Po potrditvi bo zapis trajno izbrisan.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.deleteLunchOption(lunchOption);
          }
        }
      });
    },
    deleteLunchOption(lunchOption) {
      const _this = this;
      console.log("lunchOption", lunchOption.id);

      alert("TODO!");
    }


  }
}
</script>

