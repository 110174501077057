<template>
  <div id="companiesContainer">

    <vs-breadcrumb class="mb-5">
      <li><router-link to="/">Domov</router-link><span class="vs-breadcrum--separator">/</span></li>
      <li aria-disabled="page" class="disabled-link">Donacije<span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Donacije za enote</li>
    </vs-breadcrumb>

    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               title="Dodaj novo možnost donacije za enoto" @click.stop="addLunchOption()"></vs-button>


    <vs-row>
      <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
        <v-select id="unit" :options="unitsData" class="my-5" @input="onUnitChange" style="min-width: 300px"
                  v-model="unitId" placeholder="Enota"
                  :reduce="item => item.id"
                  v-if="$globalFunctions.isAdmin() && unitsData"
                  :clearable="true"
                  label="name">

          <template v-slot:option="option">
            <vs-list-item
              :title="option.name"
              :subtitle="option.coveredMunicipalities">
              <template slot="avatar" v-if="option.name">
                <vs-avatar size="large"
                           :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                           class="mr-5"/>
              </template>
            </vs-list-item>
          </template>

          <template v-slot:selected-option="option">
            <vs-list-item
              :title="option.name"
              :subtitle="option.coveredMunicipalities">
              <template slot="avatar" v-if="option.name">
                <vs-avatar size="large"
                           :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                           class="mr-5"/>
              </template>
            </vs-list-item>
          </template>

          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
            </template>
            <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
          </template>
        </v-select>

        <v-select id="type" :options="donationsTypeData" class="my-5 ml-10" @input="onDonationTypeChange" style="min-width: 500px"
                  v-model="donationsTypeId" placeholder="Tip dopnacije"
                  :reduce="item => item.id"
                  v-if="$globalFunctions.isAdmin() && donationsTypeData"
                  :clearable="true"
                  label="name">

          <template v-slot:option="option">
            <vs-list-item
              :title="option.name">
              <template slot="avatar" v-if="option.name">
                <vs-avatar size="large"
                           :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                           class="mr-5"/>
              </template>
            </vs-list-item>
          </template>

          <template v-slot:selected-option="option">
            <vs-list-item
              :title="option.name">
              <template slot="avatar" v-if="option.name">
                <vs-avatar size="large"
                           :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                           class="mr-5"/>
              </template>
            </vs-list-item>
          </template>

          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
            </template>
            <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
          </template>
        </v-select>

      </vs-col>
    </vs-row>

    <div>

      <vs-tabs v-if="activeLunchesData && inactiveLunchesData">
        <vs-tab label="Aktivni" icon="check_circle" color="success" @click="">
          <LunchesTable :lunchOptionsData="activeLunchesData" :filter="'active'" @showCompaniesDetails="showCompaniesDetails" />
        </vs-tab>
        <vs-tab label="Neaktivni" icon="radio_button_unchecked" @click="">
          <LunchesTable :lunchOptionsData="inactiveLunchesData" :filter="'inactive'" @showCompaniesDetails="showCompaniesDetails" />
        </vs-tab>

      </vs-tabs>
    </div>

<!--    <div id="parentx">-->
<!--      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="unitSidebar" spacer-->
<!--                  v-model="sidebarActive">-->

<!--        <Company v-if="companyId && sidebarActive" :companyId="companyId" />-->
<!--      </vs-sidebar>-->
<!--    </div>-->

  </div>
</template>

<script>
  import Vue from 'vue';
  import LunchesTable from "../../components/Sopotniki/donations/LunchesTable";
  import vSelect from "vue-select";

  export default {
    name: 'Lunches',

    components: {
      'v-select': vSelect,
      LunchesTable
    },

    data() {
      return {
        lunchesData: null,
        activeLunchesData: null,
        inactiveLunchesData: null,
        companyId: null,
        sidebarActive: false,
        unitsData: null,
        unitId: null,
        donationsTypeData: null,
        donationsTypeId: null,
      }
    },

    computed: {},

    async mounted() {
      const _this = this;
      await _this.getUnits();
      await _this.getDonationTypes();
      await _this.getDailyDonations();
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.companyId = null;
      _this.sidebarActive = false;
      setTimeout(() => {next()}, 100);
    },

    methods: {
      async getUnits() {
        const _this = this;

        await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'units')
          .then((res) => {

            _this.unitsData = _.filter(res.data.data, {disabled: 0});
            _this.unitsData.push({
              id: -1,
              name: 'Vse enote',
              coveredMunicipalities: 'podatki za vse enote'
            })
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });

      },

      onUnitChange() {
        const _this = this;

        _this.getDailyDonations();
      },

      async getDonationTypes() {
        const _this = this;

        await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'donors/types')
          .then((res) => {

            _this.donationsTypeData = _.filter(res.data.data, function(donor) {
              return (donor.id === 1 || donor.id === 2 || donor.id === 8 || donor.id === 9);
            });

          })
          .catch((error) => {
            console.log("error", error);
            return false
          });
      },

      onDonationTypeChange() {
        const _this = this;

        _this.getDailyDonations();
      },

      async getDailyDonations() {
        const _this = this;

        _this.$vs.loading();


        await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'daily-donation-schedule')
          .then((res) => {

            _this.lunchesData = res.data.data;
            _this.activeLunchesData = _.filter(res.data.data, {active: 1});
            _this.inactiveLunchesData = _.filter(res.data.data, {active: 0});

            if(_this.unitId) {
              _this.activeLunchesData = _.filter( _this.activeLunchesData, (item) => item.unit.id === _this.unitId);
              _this.inactiveLunchesData = _.filter( _this.inactiveLunchesData, (item) => item.unit.id === _this.unitId);
            }

            if(_this.donationsTypeId) {
              _this.activeLunchesData = _.filter( _this.activeLunchesData, (item) => item.type.id === _this.donationsTypeId);
              _this.inactiveLunchesData = _.filter( _this.inactiveLunchesData, (item) => item.type.id === _this.donationsTypeId);
            }

            _this.$vs.loading.close();


          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      showCompaniesDetails(tr) {
        const _this = this;

        console.log("HERE!", tr);

        _this.companyId = parseInt(tr.id);
        _this.sidebarActive = true;
      },

      addLunchOption() {
        const _this = this;
        _this.$router.push({name: 'Kosilo'})
      },

    }
  }
</script>

